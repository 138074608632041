import React, { useState } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TextField, Button, OutlinedInput } from '@mui/material';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import { NumericFormat } from 'react-number-format';


export function Values({ data, updateField }) {

  const [dataExpense, setDataExpense] = useState(data.values);

  const [expense, setExpense] = useState({
    date: "",
    description: "",
    value: "",
    motive: ""
  });

  function convertValue(value) {
      return value.replace('R$', '').replace('.', '').replace(',', '.').trim();
  }

  function deconvertValue(value) {

    return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    }).format(value);
    
}

  const addExpense = () => {
    setDataExpense([...dataExpense, expense]);
    setExpense({
      date: "",
      description: "",
      value: "",
      motive: ""
    })
    updateField('values', dataExpense);
  };

  const delExpense = (index) => {
    const newDataExpense = dataExpense.filter((_, i) => i !== index);
    updateField('values', newDataExpense);
    setDataExpense(newDataExpense);
  }

  const handleInputChange = (index, field, value) => {
    const newRows = [...dataExpense];
    newRows[index][field] = value;
    setDataExpense(newRows);
    updateField('values', newRows);
  };


  return (
    <TableContainer >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontSize: '14px', textAlign: 'center' }}>Data</TableCell>
            <TableCell style={{ fontSize: '14px', textAlign: 'center' }}>Descrição</TableCell>
            <TableCell style={{ fontSize: '14px', textAlign: 'center' }}>Valor</TableCell>
            <TableCell style={{ fontSize: '14px', textAlign: 'center' }}>Motivo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(dataExpense).map((row, index) => (
            <TableRow key={index}>
              <TableCell style={{ alignItems: 'center' }}>
                <TextField sx={{ width: '150px' }} size='small' value={dataExpense[row].date} onChange={(e) => handleInputChange(index, 'date', e.target.value)} type="date" variant="outlined" required />
              </TableCell>
              <TableCell>
                <TextField sx={{ width: '280px', marginLeft: '-9px' }} size='small' value={dataExpense[row].description} onChange={(e) => handleInputChange(index, 'description', e.target.value)} type="text" variant="outlined" required />
              </TableCell>
              <TableCell>
                <NumericFormat
                  value={deconvertValue(dataExpense[row].value)}
                  allowNegative={false}
                  allowLeadingZeros={false}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalSeparator=','
                  thousandSeparator = '.'
                  prefix='R$'
                  onChange={(e) => handleInputChange(index, 'value', convertValue(e.target.value))}
                  customInput={TextField}
                  size='small'
                  placeholder='R$0,00'
                />
              </TableCell>
              <TableCell>
                <TextField sx={{ width: '280px', marginLeft: '-9px' }} size='small' value={dataExpense[row].motive} onChange={(e) => handleInputChange(index, 'motive', e.target.value)} type="text" variant="outlined" required />
              </TableCell>
              <TableCell>
                <Button onClick={() => delExpense(index)} sx={{ marginLeft: '-9px' }}>
                  <DeleteForeverOutlinedIcon />
                </Button>
              </TableCell>
            </TableRow>

          ))
          }
        </TableBody>
      </Table>
      <Button onClick={addExpense} variant="contained" color="primary" size="small" style={{ marginLeft: '15px' }}>
        Adicionar
      </Button>
    </TableContainer>
  );
}
